import { LoadingButton } from '@mui/lab';
import { FormHelperText, MenuItem, Select, Stack } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../app/hooks';
import { showMessage } from '../../features/message/message-slice';
import { InstanceEnvironment } from '../../features/sign-in/types/InstanceEnvironment';
import { getInstanceError } from '../../helper/InstanceEnvironmentHelper';
import BackHomeButton from '../back-home-button/back-home-button';

type FormValues = {
  instanceHost: string;
};

type SelectDomainProps = {
  suggestedInstances: InstanceEnvironment[];
  onInstanceSelected: (instance: InstanceEnvironment) => void;
  processingFormData?: boolean;
  disabledSubmit?: boolean;
};

const SelectDomainForm: FunctionComponent<SelectDomainProps> = ({
  suggestedInstances,
  onInstanceSelected,
  processingFormData = false,
  disabledSubmit = false,
}: SelectDomainProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    const env = suggestedInstances.find(
      (item) => item.host === formData.instanceHost
    );
    if (!env) {
      throw new Error('InstanceEnvironment cannot be defined for some reason');
    }

    const error = getInstanceError(env);

    if (error) {
      dispatch(
        showMessage({
          type: 'error',
          message: error,
        })
      );

      return;
    }

    onInstanceSelected(env);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="environmentSelectForm">
      <FormHelperText id="instancesDropdown">
        {t('selectDomain.text1')}
      </FormHelperText>
      <Select
        id="environmentSelect"
        defaultValue=""
        labelId="instancesDropdown"
        error={!!errors.instanceHost?.message}
        fullWidth
        {...register('instanceHost', {
          required: t('selectDomain.required') as string,
        })}
      >
        {suggestedInstances.map((insEnv: InstanceEnvironment) => (
          <MenuItem key={insEnv.host} value={insEnv.host}>
            {insEnv.host}
          </MenuItem>
        ))}
      </Select>
      <Stack
        id="instanceSelectStack"
        direction="column"
        spacing={2}
        sx={{
          width: '100%',
          margin: '0 auto',
          pt: '3vh',
        }}
      >
        <LoadingButton
          id="nextLoadingButton"
          type="submit"
          disabled={disabledSubmit}
          loading={processingFormData}
          variant="contained"
          fullWidth
          sx={{
            height: '40px',
          }}
        >
          {t('selectDomain.next')}
        </LoadingButton>
        <BackHomeButton />
      </Stack>
    </form>
  );
};

SelectDomainForm.defaultProps = {
  processingFormData: false,
  disabledSubmit: false,
};

export default SelectDomainForm;
