import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch } from '../../app/hooks';
import SelectDomainForm from '../../components/select-domain-form';
import { ComponentException } from '../../errors/ComponentException';
import Message from '../../features/message/message';
import {
  clearMessage,
  showMessage,
} from '../../features/message/message-slice';
import PageTitle from '../../features/page-title/page-title';
import { setPageTitle } from '../../features/page-title/page-title-slice';
import { useCheckUserByUserNameMutation } from '../../features/sign-in/sign-in-api-slice';
import { InstanceEnvironment } from '../../features/sign-in/types/InstanceEnvironment';
import { logError } from '../../helper/LoggerHelper';
import { redirect } from '../../helper/RedirectHelper';
import { getMessagesFromResponseError } from '../../helper/request-helper';
import { getIdentityProviderURL } from '../../helper/SSOHelper';

type FormValues = {
  email: string;
};

export const SignIn: FunctionComponent<{ children?: any }> = () => {
  const { t, i18n } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();
  const [checkUserByUserName, { isLoading }] = useCheckUserByUserNameMutation();
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);
  const [suggestedInstances, setSuggestedInstances] = useState<
    InstanceEnvironment[]
  >([]);
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const desiredInstance = searchParams.get('instance');

  useEffect(() => {
    dispatch(setPageTitle({ title: 'sso.pageTitle' }));

    return () => {
      dispatch(clearMessage());
    };
  }, []);

  const handleError = (e: unknown) => {
    logError(e);
    const errorMessages: string[] = getMessagesFromResponseError(e);
    dispatch(
      showMessage({
        type: 'error',
        messages: errorMessages,
      })
    );
  };

  const redirectToIdentityProvider = async (
    instance: InstanceEnvironment,
    providedEmail: string
  ) => {
    const url = await getIdentityProviderURL(
      instance,
      providedEmail,
      i18n.language
    );
    redirect(url);
  };

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      dispatch(clearMessage());
      setSubmitIsLoading(true);
      setEmail(formData.email);

      let availableEnvs: Array<InstanceEnvironment> = await checkUserByUserName(
        { username: formData.email }
      ).unwrap();

      console.debug(`Found instances: ${JSON.stringify(availableEnvs)}`);

      if (desiredInstance) {
        availableEnvs = availableEnvs.filter((i) => i.host === desiredInstance);

        console.debug(
          `Found available instances: ${JSON.stringify(availableEnvs)}, for desired: ${desiredInstance}`
        );

        if (availableEnvs.length !== 1) {
          throw new ComponentException('sso.canNotIdentifyInstance');
        }
      }

      if (availableEnvs.length === 0) {
        throw new Error('login.loginError');
      } else if (availableEnvs.length === 1) {
        await redirectToIdentityProvider(availableEnvs['0'], formData.email);
      } else {
        setSuggestedInstances(availableEnvs);
      }
    } catch (e) {
      handleError(e);
    } finally {
      setSubmitIsLoading(false);
    }
  };
  const navigate = useNavigate();
  const onClickToLoginPage: MouseEventHandler<HTMLButtonElement> = (
    e
  ): void => {
    e.preventDefault();

    navigate(`/${i18n.language}/login`, { replace: true });
  };

  const onInstanceSelected = async (instance: InstanceEnvironment) => {
    setSubmitIsLoading(true);

    try {
      if (!email) {
        throw new ComponentException('email is required.');
      }

      await redirectToIdentityProvider(instance, email);
    } catch (e) {
      handleError(e);
    }

    setSubmitIsLoading(false);
  };

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Container maxWidth="xs">
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              paddingBottom: '5vh',
            }}
          >
            <PageTitle />
          </Grid>
          <Grid item xs={12}>
            <Message />
            {suggestedInstances.length > 1 && (
              <SelectDomainForm
                suggestedInstances={suggestedInstances}
                onInstanceSelected={onInstanceSelected}
                processingFormData={submitIsLoading}
              />
            )}
            {suggestedInstances.length === 0 && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1">
                      {t('sso.emailLabel')}
                    </Typography>
                  </Stack>
                  <TextField
                    id="ssoEmailInputField"
                    autoFocus
                    inputProps={{ tabIndex: 1, 'data-testid': 'sso-email' }}
                    disabled={isLoading || submitIsLoading}
                    placeholder={t('sso.emailPlaceholder')}
                    autoComplete="email"
                    helperText={errors.email?.message}
                    error={!!errors.email?.message}
                    fullWidth
                    type="email"
                    {...register('email', {
                      onChange: (e) => {
                        e.target.value = e.target.value.trim();
                      },
                      required: t('sso.emailEmptyError') as string,
                    })}
                  />
                </Box>
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    width: '100%',
                    margin: '0 auto',
                    pt: '3vh',
                  }}
                >
                  <Box textAlign="center">
                    <LoadingButton
                      id="ssoLoginBtn"
                      type="submit"
                      loading={isLoading || submitIsLoading}
                      variant="contained"
                      tabIndex={3}
                      fullWidth
                      sx={{
                        height: '40px',
                      }}
                    >
                      {t('sso.submitLabel')}
                    </LoadingButton>
                  </Box>
                  <Box textAlign="center">
                    <Button
                      id="ssoBackToLoginPage"
                      variant="text"
                      onClick={onClickToLoginPage}
                    >
                      {t('sso.backToLoginPage')}
                    </Button>
                  </Box>
                </Stack>
              </form>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
